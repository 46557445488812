import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faUserAstronaut,
  faRocket,
  faMobileAlt,
  faNetworkWired,
  faVrCardboard,
  faDatabase,
  faDesktop,
  faBars,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  fab,
  faUserAstronaut,
  faRocket,
  faMobileAlt,
  faNetworkWired,
  faVrCardboard,
  faDatabase,
  faDesktop,
  faBars
)
