/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Styles from "./layout.module.css"
import "../utils/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Header from "./header"
import { Link } from "gatsby"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer className={Styles.footer}>
          © {new Date().getFullYear()}{" "}
          <a className="text-blue-700" href="https://www.treadway.ai">
            Treadway Technologies, Inc.
          </a>
          {` | `}
          <a
            href="https://www.facebook.com/pawsandtailsai/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700"
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} width="16" />{" "}
            <span> Like us on Facebook</span>
          </a>
          {` | `}
          Built with{" "}
          <a
            className="text-blue-700"
            href="https://www.gatsbyjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gatsby
          </a>
          {` | `}
          <Link to="terms-and-conditions" className="text-blue-700">
            Terms
          </Link>
          {` | `}
          <Link to={`/privacy-policy`} className="text-blue-700">
            Privacy Policy
          </Link>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
