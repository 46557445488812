import React, { useState, useEffect } from "react"
import Styles from "./header.module.css"
import Logo from "../images/paws+tails_logo.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)
  useEffect(() => {
    // The debounce function receives our function as a parameter
    const debounce = fn => {
      // This holds the requestAnimationFrame reference, so we can cancel it if we wish
      let frame

      // The debounce function returns a new function that can receive a variable number of arguments
      return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
          cancelAnimationFrame(frame)
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
          // Call our function and pass any params we received
          fn(...params)
        })
      }
    }

    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    const storeScroll = () => {
      document.getElementById("nav").dataset.scroll = window.scrollY
    }

    // Listen for new scroll events, here we debounce our `storeScroll` function
    window.addEventListener("scroll", debounce(storeScroll), {
      passive: true,
    })

    // Update scroll position for first time
    storeScroll()
  })

  return (
    <nav
      className={`${
        isExpanded ? `${Styles.background}` : ``
      } flex items-center justify-between flex-wrap p-6 w-screen`}
      id={`nav`}
    >
      <div className="flex items-center flex-shrink-0 mr-6">
        <AniLink cover to="/" bg="#fff" className="block">
          <img
            src={Logo}
            alt="Paws &amp; Tails AI logo"
            width="100"
            title="Paws &amp; Tails AI logo"
          />
        </AniLink>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => toggleExpansion(!isExpanded)}
          className="flex items-center px-3 py-2 rounded border-black border-solid border"
          aria-label="Site Menu"
        >
          <FontAwesomeIcon icon={faBars} width="24" size="lg" />
        </button>
      </div>
      <div
        className={`${Styles.subNav} ${
          isExpanded ? `${Styles.checked}` : ``
        } w-full block flex-grow lg:flex lg:items-center lg:w-auto lg:opacity-100 pt-5`}
      >
        {/* Nav menu */}
        <ul className="text-sm lg:flex-grow sm:h-auto">
          <li className="lg:inline-block">
            <AniLink
              cover
              bg="#fff"
              to={`/about-paws-and-tails`}
              className="md:pointer-events-auto block mt-2 lg:inline-block lg:mt-0 hover:text-teal-400 lg:opacity-100 lg:mt-12 sm:opacity-0 md:text-lg"
            >
              About
            </AniLink>
          </li>
          <li className="lg:inline-block">
            <a
              href="https://www.treadway.ai/contact-treadway-ai"
              target="_blank"
              rel="noopener noreferrer"
              className="md:pointer-events-auto block mt-2 lg:inline-block lg:mt-0 hover:text-teal-400 lg:opacity-100 lg:mt-12 md:text-lg"
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header
